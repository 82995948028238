export const environment = {
  'production': false,
  'stage': 'dev',
  'deployment': 'id',
  'country_code': 'id',
  'supported_languages': [
    'en',
    'id'
  ],
  'default_language': 'id',
  'mapbox_access_token': 'pk.eyJ1IjoicGV0YWJlbmNhbmEiLCJhIjoiY2s2MjF1cnZmMDlxdzNscWc5MGVoMTRkeCJ9.PGcoQqU6lBrcLfBmvTrWrQ',
  'data_server': 'https://dev-api.petabencana.id/',
  'app': 'https://petabencana.id/',
  'default_location': {
    'lat': -6.175392,
    'lng': 106.827153
  },
  'loc_search_suffix': ', Indonesia',
  'supportedDecks': [
    {
      'path': 'flood',
      'loadChildren': './flood/flood.module#FloodModule'
    },
    {
      'path': 'fire',
      'loadChildren': './fire/fire.module#FireModule'
    },
    {
      'path': 'haze',
      'loadChildren': './haze/haze.module#HazeModule'
    },
    {
      'path': 'earthquake',
      'loadChildren': './earthquake/earthquake.module#EarthquakeModule'
    },
    {
      'path': 'wind',
      'loadChildren': './wind/wind.module#WindModule'
    },
    {
      'path': 'volcano',
      'loadChildren': './volcano/volcano.module#VolcanoModule'
    },
    {
      'path': 'notifications',
      'loadChildren': './notifications/notifications.module#NotificationsModule'
    },
    {
      'path': 'need',
      'loadChildren': './need/need.module#NeedModule'
    },
    {
      'path': 'giver',
      'loadChildren': './giver/giver.module#GiverModule'
    }
  ],
  'supportedCards': {
    'flood': [
      {
        'path': 'location',
        'loadChildren': '../../cards/location/location.module#LocationModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'depth',
        'loadChildren': '../../cards/depth/depth.module#DepthModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'photo',
        'loadChildren': '../../cards/photo/photo.module#PhotoModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'description',
        'loadChildren': '../../cards/description/description.module#DescriptionModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'review',
        'loadChildren': '../../cards/review/review.module#ReviewModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'thank',
        'loadChildren': '../../cards/thank/thank.module#ThankModule',
        'data': {
          'preload': true
        }
      }
    ],
    'fire': [
      {
        'path': 'firedistance',
        'loadChildren': '../../cards/firedistance/firedistance.module#FiredistanceModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'fireestimate',
        'loadChildren': '../../cards/fireestimate/fireestimate.module#FireestimateModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'photo',
        'loadChildren': '../../cards/photo/photo.module#PhotoModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'description',
        'loadChildren': '../../cards/description/description.module#DescriptionModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'review',
        'loadChildren': '../../cards/review/review.module#ReviewModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'thank',
        'loadChildren': '../../cards/thank/thank.module#ThankModule',
        'data': {
          'preload': true
        }
      }
    ],
    'haze': [
      {
        'path': 'location',
        'loadChildren': '../../cards/location/location.module#LocationModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'visibility',
        'loadChildren': '../../cards/visibility/visibility.module#VisibilityModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'airquality',
        'loadChildren': '../../cards/airquality/airquality.module#AirqualityModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'photo',
        'loadChildren': '../../cards/photo/photo.module#PhotoModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'description',
        'loadChildren': '../../cards/description/description.module#DescriptionModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'review',
        'loadChildren': '../../cards/review/review.module#ReviewModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'thank',
        'loadChildren': '../../cards/thank/thank.module#ThankModule',
        'data': {
          'preload': true
        }
      }
    ],
    'earthquake': [
      {
        'path': 'type',
        'loadChildren': '../../cards/type/type.module#TypeModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'location',
        'loadChildren': '../../cards/location/location.module#LocationModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'structure',
        'loadChildren': '../../cards/structure/structure.module#StructureModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'accessibility',
        'loadChildren': '../../cards/accessibility/accessibility.module#AccessibilityModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'condition',
        'loadChildren': '../../cards/condition/condition.module#ConditionModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'photo',
        'loadChildren': '../../cards/photo/photo.module#PhotoModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'description',
        'loadChildren': '../../cards/description/description.module#DescriptionModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'review',
        'loadChildren': '../../cards/review/review.module#ReviewModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'thank',
        'loadChildren': '../../cards/thank/thank.module#ThankModule',
        'data': {
          'preload': true
        }
      }
    ],
    'wind': [
      {
        'path': 'location',
        'loadChildren': '../../cards/location/location.module#LocationModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'impact',
        'loadChildren': '../../cards/impact/impact.module#ImpactModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'photo',
        'loadChildren': '../../cards/photo/photo.module#PhotoModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'description',
        'loadChildren': '../../cards/description/description.module#DescriptionModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'review',
        'loadChildren': '../../cards/review/review.module#ReviewModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'thank',
        'loadChildren': '../../cards/thank/thank.module#ThankModule',
        'data': {
          'preload': true
        }
      }
    ],
    'volcano': [
      {
        'path': 'location',
        'loadChildren': '../../cards/location/location.module#LocationModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'sign',
        'loadChildren': '../../cards/sign/sign.module#SignModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'photo',
        'loadChildren': '../../cards/photo/photo.module#PhotoModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'description',
        'loadChildren': '../../cards/description/description.module#DescriptionModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'evacuationnumber',
        'loadChildren': '../../cards/evacuationnumber/evacuationnumber.module#EvacuationnumberModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'evacuationarea',
        'loadChildren': '../../cards/evacuationarea/evacuationarea.module#EvacuationareaModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'review',
        'loadChildren': '../../cards/review/review.module#ReviewModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'thank',
        'loadChildren': '../../cards/thank/thank.module#ThankModule',
        'data': {
          'preload': true
        }
      }
    ],
    'notifications': [
      {
        'path': 'region',
        'loadChildren': '../../cards/region/region.module#RegionModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'summary',
        'loadChildren': '../../cards/summary/summary.module#SummaryModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'thankyou',
        'loadChildren': '../../cards/thankyou/thankyou.module#ThankyouModule',
        'data': {
          'preload': true
        }
      }
    ],
    'need': [
      {
        'path': 'location',
        'loadChildren': '../../cards/location/location.module#LocationModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'products',
        'loadChildren': '../../cards/products/products.module#ProductsModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'productreview',
        'loadChildren': '../../cards/productreview/productreview.module#ProductreviewModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'thank',
        'loadChildren': '../../cards/thank/thank.module#ThankModule',
        'data': {
          'preload': true
        }
      }
    ],
    'giver': [
      {
        'path': 'donate',
        'loadChildren': '../../cards/donate/donate.module#DonateModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'dateandtime',
        'loadChildren': '../../cards/dateandtime/dateandtime.module#DateandtimeModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'contact',
        'loadChildren': '../../cards/contact/contact.module#ContactModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'donationreview',
        'loadChildren': '../../cards/donationreview/donationreview.module#DonationreviewModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'thank',
        'loadChildren': '../../cards/thank/thank.module#ThankModule',
        'data': {
          'preload': true
        }
      }
    ]
  }
};
